import { useEffect, useState } from "react";
import Layout from "../../component/layout/Layout";
import { HomeHeader, HomeImageBox } from "./css/styled.Home";
import useHomeService from "./userHomeService";

function Home() {
    const { getHomeDog } = useHomeService();

    const [homeDogImage, setHomeDogImage] = useState();
    useEffect(() => {
        getHomeDog({ setHomeDogImage });
    }, []);

    return (
        <Layout>
            <HomeHeader>
                <HomeImageBox>
                    {homeDogImage !== undefined ? (
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "20px",
                                objectFit: "cover",
                            }}
                            src={homeDogImage.dogImageJson.fileUrl}
                            alt={homeDogImage.dogImageJson.fileName}
                        />
                    ) : null}
                </HomeImageBox>
            </HomeHeader>
        </Layout>
    );
}

export default Home;
