import styled from "styled-components";

export const NoticeBoardBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 400px;
    min-height: calc(100vh - 350px);

    @media (max-width: 768px) {
        width: 100%;
        height: 500px;
        padding: 0 20px;
    }
`;

export const NoticeEditorBoardBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 400px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;
    }
`;

export const PostHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
`;

export const PostHeaderTitle = styled.span`
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
`;

export const PostHeaderAuthor = styled.span`
    font-size: 14px;
    width: 50%;
    text-align: center;
    color: #333;
`;

export const PostList = styled.div`
    list-style: none;
    width: 100%;
`;

export const PostItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;

    cursor: pointer;
`;

export const PostTitle = styled.span`
    font-size: 14px;
    width: 100%;
    margin-left: 50px;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const PostAuthor = styled.span`
    font-size: 14px;
    width: 50%;
    text-align: center;
    color: #333;
`;
