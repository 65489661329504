import Layout from "../../../component/layout/Layout";
import { Li } from "../../../component/layout/css/styled.layout";
import { useEffect, useState } from "react";
import { InputBox } from "../../../component/form/Form";
import { useChangeHandler } from "../../../common/CommonUtil";
import { useParams } from "react-router-dom";
import { noticeBoardForm } from "./init";
import Editor from "../../../component/board/Editor";
import { NoticeEditorBoardBox } from "./css/styled.NoticeBoard";
import useBoardService from "../useBoardService";
import { Box } from "../../../component/box/css/styled.box";

function NoticeBoardUpdate() {
    let { id } = useParams();
    const { state, setState, onChangeHandler } =
        useChangeHandler(noticeBoardForm);
    const { getUpdateNoticeBoard, patchNoticeBoard } = useBoardService();
    const [content, setContent] = useState();

    useEffect(() => {
        const query = {
            id: id,
        };
        getUpdateNoticeBoard({ query, setState });
    }, []);

    useEffect(() => {
        setContent(state.noticeBoardContent);
    }, [state]);

    const onClickUpdate = () => {
        state.id = id;
        state.noticeBoardContent = content;
        patchNoticeBoard(state);
    };

    const button = () => {
        return (
            <Li>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickUpdate()}
                >
                    저장
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <NoticeEditorBoardBox>
                <Box style={{ padding: "0 0 20px 0" }}>
                    <InputBox
                        label="제목"
                        type="text"
                        width="100%"
                        labelWidth="15%"
                        height="50px"
                        id="noticeBoardTitle"
                        value={state.noticeBoardTitle}
                        onChangeHandler={onChangeHandler}
                    />
                </Box>
                <Editor
                    content={content}
                    setContent={setContent}
                    boardType={"notice"}
                />
            </NoticeEditorBoardBox>
        </Layout>
    );
}

export default NoticeBoardUpdate;
