import axios from 'axios';

export const request = axios.create({
    baseURL: 'https://api.withbinary.co.kr',
    // baseURL: "http://localhost:8000",
    timeout: 60000,
});

request.interceptors.request.use(
    (config) => config,
    () => {},
);
