import { jwtDecode } from "jwt-decode";

export const userToken = () => {
    return sessionStorage.getItem("token");
};

export const admin = () => {
    const token = userToken();

    if (token !== null) {
        return jwtDecode(token).userType === "ADMIN";
    } else {
        return false;
    }
};
