import React from "react";
import { Route } from "react-router-dom";
import Volunteer from "../page/withthedogs/volunteer/Volunteer";
import VolunteeDetail from "../page/withthedogs/volunteer/VolunteerDetail";
import VolunteerWrite from "../page/withthedogs/volunteer/VolunteerWrite";
import VolunteerUpdate from "../page/withthedogs/volunteer/VolunteerUpdate";
import Dog from "../page/withthedogs/dog/Dog";
import AddDogProfile from "../page/withthedogs/dog/AddDogProfile";
import DogProfile from "../page/withthedogs/dog/DogProfile";
import UpdateDogProfile from "../page/withthedogs/dog/UpdateDogProfile";

function WiththedogsRouter() {
    return (
        <>
            <Route path="/withthedogs/dog" element={<Dog />} />
            <Route path="/withthedogs/dog/:shelter" element={<Dog />} />
            <Route path="/withthedogs/dog/new" element={<AddDogProfile />} />
            <Route
                path="/withthedogs/dog/:shelterCode/:dogCode"
                element={<DogProfile />}
            />
            <Route
                path="/withthedogs/dog/update/:shelterCode/:dogCode"
                element={<UpdateDogProfile />}
            />
            {/* <Route path="/withthedogs/volunteer" element={<Volunteer />} />
            <Route
                path="/withthedogs/volunteer/write"
                element={<VolunteerWrite />}
            />
            <Route
                path="/withthedogs/volunteer/:id"
                element={<VolunteeDetail />}
            />
            <Route
                path="/withthedogs/volunteer/update/:id"
                element={<VolunteerUpdate />}
            /> */}
        </>
    );
}

export default WiththedogsRouter;
