import React, { useCallback } from "react";
import { loginForm } from "./init";
import { useChangeHandler } from "../../../common/CommonUtil";
import useUserService from "../useUserService";
import { LoginBox } from "../css/styled.user";
import { Box } from "../../../component/box/css/styled.box";
import { InputBox } from "../../../component/form/Form";
import { Button } from "../../../component/button/css/styled.button";
import Layout from "../../../component/layout/Layout";

function UserLogin() {
    const { state, onChangeHandler } = useChangeHandler(loginForm);
    const { postUserLogin } = useUserService();

    const onClickLogin = useCallback(() => {
        postUserLogin(state);
    });

    return (
        <Layout>
            <LoginBox>
                <form>
                    <Box
                        style={{
                            display: "flex",
                            height: "100px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <h1>로그인</h1>
                    </Box>
                    <Box style={{ width: "300px" }}>
                        <InputBox
                            label="ID"
                            type="text"
                            width="100%"
                            labelWidth="30%"
                            height="50px"
                            id="userId"
                            onChangeHandler={onChangeHandler}
                        />
                        <InputBox
                            label="비밀번호"
                            type="password"
                            width="100%"
                            labelWidth="30%"
                            height="50px"
                            id="userPassword"
                            onChangeHandler={onChangeHandler}
                        />
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            height: "100px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            style={{
                                width: "300px",
                                height: "50px",
                                border: "1px solid gray",
                                borderRadius: "10px",
                            }}
                            type="button"
                            onClick={() => onClickLogin()}
                        >
                            로그인
                        </Button>
                    </Box>
                </form>
            </LoginBox>
        </Layout>
    );
}

export default UserLogin;
