import { useMutation } from "react-query";
import {
    postDogAPI,
    getDogAPI,
    patchDogAPI,
    deleteDogAPI,
    getDogListAPI,
    getDogImageListAPI,
    postDogImageAPI,
    deleteDogImageAPI,
} from "../../../api/service/WithTheDogsServiceAPI";
import { useNavigate } from "react-router-dom";

function useDogsService() {
    const navigate = useNavigate();

    const { mutate: getDogListMutate } = useMutation(getDogListAPI);
    const { mutate: getDogMutate } = useMutation(getDogAPI);
    const { mutate: postDogMutate } = useMutation(postDogAPI);
    const { mutate: patchDogMutate } = useMutation(patchDogAPI);
    const { mutate: deleteDogMutate } = useMutation(deleteDogAPI);
    const { mutate: postDogImageMutate } = useMutation(postDogImageAPI);
    const { mutate: getDogImageListMutate } = useMutation(getDogImageListAPI);
    const { mutate: deleteDogImageMutate } = useMutation(deleteDogImageAPI);

    // 강아지 목록
    const getDogList = ({ query, setDogList, setPage }) => {
        getDogListMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setPage(data.result);
                    setDogList(data.result.list);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 강아지 상세
    const getDog = ({ query, setState }) => {
        getDogMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setState(data.result);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 강아지 등록
    const postDog = (data) => {
        postDogMutate(
            { data },
            {
                onSuccess: (data) => {
                    navigate("/withthedogs/dog");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 강아지 수정
    const patchDog = (data) => {
        patchDogMutate(
            { data },
            {
                onSuccess: (data) => {
                    navigate("/withthedogs/dog");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 강아지 삭제
    const deleteDog = ({ query }) => {
        deleteDogMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    navigate("/withthedogs/dog");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 강아지 이미지 추가 등록
    const postDogImage = (data) => {
        postDogImageMutate(
            { data },
            {
                onSuccess: (data) => {
                    window.location.reload();
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 강아지 이미지 목록
    const getDogImageList = ({ query, setDogImageList }) => {
        getDogImageListMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setDogImageList(data.result);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 강아지 이미지 삭제
    const deleteDogImage = ({ query }) => {
        deleteDogImageMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    window.location.reload();
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    return {
        getDogList,
        getDog,
        postDog,
        patchDog,
        deleteDog,
        postDogImage,
        getDogImageList,
        deleteDogImage,
    };
}

export default useDogsService;
