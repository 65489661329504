import { useMutation } from "react-query";
import { getHomeDogAPI } from "../../api/service/HomeServiceAPI";

function useHomeService() {
    const { mutate: getHomeDogMutate } = useMutation(getHomeDogAPI);

    // 홈 화면 강아지
    const getHomeDog = ({ setHomeDogImage }) => {
        getHomeDogMutate(
            {},
            {
                onSuccess: (data) => {
                    setHomeDogImage(data.result);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    return { getHomeDog };
}

export default useHomeService;
