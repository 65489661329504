import { useEffect, useState } from "react";
import useShelterService from "../shelter/useShelterService";
import { Li, Nav, Ul } from "../../../component/layout/css/styled.layout";
import {
    DogSearchBox,
    ShelterButton,
    ShelterButtonBox,
} from "./css/styled.Dog";

function ShelterList({ setShelterCode }) {
    const [shelterList, setShelterList] = useState([]);
    const { getShelterList } = useShelterService();

    useEffect(() => {
        const query = {};
        getShelterList({ query, setShelterList });
    }, []);

    const list = [
        {
            title: "전체보기",
            value: "",
        },
    ];

    list.push(...shelterList);

    const onClickShelter = (shelterCode) => {
        setShelterCode(shelterCode);
    };

    return (
        <DogSearchBox>
            <div style={{ paddingLeft: "10px" }}>
                {list.map((item) => (
                    <Li key={item.value}>
                        <ShelterButton
                            onClick={() => onClickShelter(item.value)}
                        >
                            {item.title}
                        </ShelterButton>
                    </Li>
                ))}
            </div>
        </DogSearchBox>
    );
}

export default ShelterList;
