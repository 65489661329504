import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
    deleteNoticeBoardAPI,
    getNoticeBoardAPI,
    getNoticeBoardListAPI,
    getUpdateNoticeBoardAPI,
    patchNoticeBoardAPI,
    postNoticeBoardAPI,
} from "../../api/service/BoardServiceAPI";

function useBoardService() {
    const navigate = useNavigate();

    const { mutate: getNoticeBoardListMutate } = useMutation(
        getNoticeBoardListAPI,
    );
    const { mutate: postNoticeBoardMutate } = useMutation(postNoticeBoardAPI);
    const { mutate: getNoticeBoardMutate } = useMutation(getNoticeBoardAPI);
    const { mutate: getUpdateNoticeBoardMutate } = useMutation(
        getUpdateNoticeBoardAPI,
    );
    const { mutate: patchNoticeBoardMutate } = useMutation(patchNoticeBoardAPI);
    const { mutate: deleteNoticeBoardMutate } =
        useMutation(deleteNoticeBoardAPI);

    // 공지사항 목록
    const getNoticeBoardList = ({ query, setNoticeList, setPage }) => {
        getNoticeBoardListMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setPage(data.result);
                    setNoticeList(data.result.list);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 공지사항 등록
    const postNoticeBoard = (data) => {
        postNoticeBoardMutate(
            { data },
            {
                onSuccess: (data) => {
                    navigate("/notice");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 공지사항
    const getNoticeBoard = ({ query, setNoticeBoard }) => {
        getNoticeBoardMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setNoticeBoard(data.result);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 공지사항 수정
    const getUpdateNoticeBoard = ({ query, setState }) => {
        getUpdateNoticeBoardMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setState(data.result);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 공지사항 수정
    const patchNoticeBoard = (data) => {
        patchNoticeBoardMutate(
            { data },
            {
                onSuccess: (data) => {
                    navigate("/notice");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 공지사항 삭제
    const deleteNoticeBoard = ({ query }) => {
        deleteNoticeBoardMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    navigate("/notice");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    return {
        getNoticeBoardList,
        postNoticeBoard,
        getNoticeBoard,
        getUpdateNoticeBoard,
        patchNoticeBoard,
        deleteNoticeBoard,
    };
}

export default useBoardService;
