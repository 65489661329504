import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import MainRouter from "./router/MainRouter";
import UserRouter from "./router/UserRouter";
import WiththedogsRouter from "./router/WiththedogsRouter";
import BoardRouter from "./router/BoardRouter";
import Error from "./page/home/Error";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Navigate to="/error" />} />
                <Route path="/error" element={<Error />} />
                {MainRouter()}
                {UserRouter()}
                {BoardRouter()}
                {WiththedogsRouter()}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
