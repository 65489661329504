import { useMutation } from "react-query";
import { getShelterListAPI } from "../../../api/service/WithTheDogsServiceAPI";

function useShelterService() {
    const { mutate: getShelterListMutate } = useMutation(getShelterListAPI);

    // shelter 목록
    const getShelterList = ({ query, setShelterList }) => {
        getShelterListMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    const shelterList = data.result.map((data) => {
                        return {
                            title: data.shelterName,
                            value: data.shelterCode,
                        };
                    });
                    setShelterList(shelterList);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    return {
        getShelterList,
    };
}

export default useShelterService;
