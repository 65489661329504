import styled from "styled-components";

export const DogProfileBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 600px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
    }
`;

export const DogProfileImageBox = styled.div`
    width: 300px;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 20px;
`;

export const DogProfileDetailBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 600px;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
    }
`;

export const DogProfileLeftBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 600px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

export const DogProfileRightBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 600px;
    width: 500px;
    padding: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 250px;
        min-height: calc(100vh - 700px);
        width: 100%;
        padding: 0 30px;

        overflow-y: auto;
        overflow-x: hidden;
    }
`;

export const DogProfileRightImageBox = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;

export const DogImageBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: block;
    width: 100px;
    height: 100px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;
