import styled from "styled-components";

export const HomeHeader = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

export const HomeImageBox = styled.div`
    display: flex;
    width: 25%;
    height: 25%;

    @media (max-width: 768px) {
        width: 50%;
        height: 50%;
    }
`;
