import { Box } from "../../box/css/styled.box";
import { Footer } from "../css/styled.layout";

function LayoutFooter() {
    const Copyright = () => {
        return (
            <Box>
                {"All Copyright © "}
                <b>withbinary</b> {new Date().getFullYear()}
                {"."}
            </Box>
        );
    };

    return (
        <Footer>
            <Box>
                <p>
                    This website runs on Ubuntu <br /> with Proxmox VE, Nginx
                    and Docker
                </p>
                <Copyright />
            </Box>
        </Footer>
    );
}

export default LayoutFooter;
