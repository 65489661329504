import { SelectBox } from "../../../component/form/Form";

export const dogForm = {
    userId: "",
    shelterCode: "",
    dogName: "",
    dogInfo: "",
    dogLeaveYn: "N",
};

export const dogLeaveButton = (disabled, state, onChangeHandler) => {
    const options = [
        {
            title: "NO",
            value: "Y",
        },
        {
            title: "YES",
            value: "N",
        },
    ];

    return (
        <SelectBox
            label="보호중"
            width="300px"
            labelWidth="30%"
            height="40px"
            id="dogLeaveYn"
            value={state.dogLeaveYn}
            onChangeHandler={onChangeHandler}
            options={options}
            disabled={disabled}
        />
    );
};
