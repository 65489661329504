import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBoardService from "../useBoardService";
import { Li } from "../../../component/layout/css/styled.layout";
import Layout from "../../../component/layout/Layout";
import { NoticeEditorBoardBox } from "./css/styled.NoticeBoard";
import { InputBox } from "../../../component/form/Form";
import Editor from "../../../component/board/Editor";
import { Box } from "../../../component/box/css/styled.box";

function NoticeBoardDetail() {
    const navigate = useNavigate();
    let { id } = useParams();
    const { getNoticeBoard, deleteNoticeBoard } = useBoardService();

    const [noticeBoard, setNoticeBoard] = useState();
    useEffect(() => {
        const query = {
            id: id,
        };

        getNoticeBoard({ query, setNoticeBoard });
    }, []);

    const onClickUpdate = () => {
        navigate(`/notice/board/update/${id}`);
    };

    const onClickDelete = () => {
        const query = {
            id: id,
        };
        deleteNoticeBoard({ query });
    };

    const button = () => {
        return (
            <>
                <Li>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => onClickUpdate()}
                    >
                        수정
                    </div>
                </Li>
                <Li>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => onClickDelete()}
                    >
                        삭제
                    </div>
                </Li>
            </>
        );
    };

    return (
        <Layout button={button()}>
            {noticeBoard && (
                <NoticeEditorBoardBox>
                    <Box style={{ padding: "0 0 20px 0" }}>
                        <InputBox
                            label="제목"
                            type="text"
                            disabled={true}
                            width="100%"
                            labelWidth="15%"
                            height="50px"
                            id="noticeBoardTitle"
                            value={noticeBoard.noticeBoardTitle}
                        />
                    </Box>
                    <Editor
                        content={noticeBoard.noticeBoardContent}
                        setContent={null}
                        type={"notice"}
                        readOnly={true}
                    />
                </NoticeEditorBoardBox>
            )}
        </Layout>
    );
}

export default NoticeBoardDetail;
