import { request } from "./Config";

export const axiosGet = async (url, data, config) =>
    RequestAPI("GET", url, data, config);
export const axiosPost = async (url, data, config) =>
    RequestAPI("POST", url, data, config);
export const axiosPatch = async (url, data, config) =>
    RequestAPI("PATCH", url, data, config);
export const axiosDelete = async (url, data, config) =>
    RequestAPI("DELETE", url, data, config);

const RequestAPI = async (method, url, data = {}, config = {}) => {
    const newData = data;

    config.headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        userId:
            sessionStorage.getItem("userId") === null
                ? "guest"
                : sessionStorage.getItem("userId"),
    };

    try {
        let result;

        switch (method) {
            case "GET":
                config.params = newData;
                result = await request.get(url, config);
                break;
            case "POST":
                result = await request.post(url, newData, config);
                break;
            case "PATCH":
                result = await request.patch(url, newData, config);
                break;
            case "DELETE":
                result = await request.delete(url, {
                    data: newData,
                    headers: config.headers,
                });
                break;
            default:
                result = "error";
        }

        return result.data;
    } catch (error) {
        alert(error);
        console.log(error);
    }
};

/**
 * 로그인 요청
 */
export const axiosLogin = async (url, data, config) =>
    RequestLoginAPI(url, data, config);

const RequestLoginAPI = async (url, data = {}, config = {}) => {
    const newData = data;

    try {
        return await request.post(url, newData, config);
    } catch (error) {
        alert(error);
        console.log(error);
    }
};
