import React, { useCallback, useEffect, useState } from "react";
import { dogForm, dogLeaveButton, showLikePeople } from "./DogUtil";
import { useChangeHandler } from "../../../common/CommonUtil";
import DogProfileImage from "./DogProfileImage";
import { InputBox, SelectBox } from "../../../component/form/Form";
import { Box } from "../../../component/box/css/styled.box";
import { admin } from "../../../common/SessionUtil";
import useDogService from "./useDogsService";
import Layout from "../../../component/layout/Layout";
import { DogProfileBox, DogProfileImageBox } from "./css/styled.DogProfile";
import useShelterService from "../shelter/useShelterService";
import { useParams } from "react-router-dom";
import { Li } from "../../../component/layout/css/styled.layout";

function UpdateDogProfile() {
    let { shelterCode, dogCode } = useParams();
    const { state, setState, onChangeHandler } = useChangeHandler(dogForm);
    const { getDog, patchDog } = useDogService();
    const { getShelterList } = useShelterService();

    const [disabled, setDisabled] = useState(false);
    const [image, setImage] = useState();

    const [shelterList, setShelterList] = useState([]);

    useEffect(() => {
        const query = {
            shelterCode: shelterCode,
            dogCode: dogCode,
        };
        getDog({ query, setState });
        getShelterList({ query, setShelterList });

        if (!admin()) {
            setDisabled(true);
        }
    }, []);

    useEffect(() => {
        if (shelterList.length > 0) {
            state.shelterCode = shelterList[0].value;
        }
    }, [shelterList.length > 0]);

    const onClickUpdate = useCallback(() => {
        state.userId = sessionStorage.getItem("userId");

        const update = new Blob([JSON.stringify(state)], {
            type: "application/json",
        });

        const formData = new FormData();
        formData.append("update", update);
        formData.append("file", image);

        patchDog(formData);
    });

    const button = () => {
        return (
            <Li>
                <div style={{ cursor: "pointer" }} onClick={onClickUpdate}>
                    저장
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <DogProfileBox>
                <DogProfileImageBox>
                    <DogProfileImage dog={state} setImage={setImage} />
                </DogProfileImageBox>
                <form>
                    <Box>
                        <InputBox
                            label="이름"
                            type="text"
                            width="300px"
                            labelWidth="30%"
                            height="40px"
                            disabled={disabled}
                            id="dogName"
                            value={state.dogName}
                            onChangeHandler={onChangeHandler}
                        />
                    </Box>
                    <Box>
                        <SelectBox
                            label="보호소"
                            width="300px"
                            labelWidth="30%"
                            height="50px"
                            id="shelterCode"
                            value={state.shelterCode}
                            onChangeHandler={onChangeHandler}
                            options={shelterList}
                        />
                    </Box>
                    <Box>{dogLeaveButton(false, state, onChangeHandler)}</Box>
                </form>
            </DogProfileBox>
        </Layout>
    );
}

export default UpdateDogProfile;
