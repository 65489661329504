import styled from "styled-components";

export const DogProfileModalBox = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const DogProfileModalBackgroundBox = styled.div`
    position: relative;
    background-color: white;
    border-radius: 10px;
    width: 700px;
    height: 700px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        height: 480px;
        width: 100%;
    }
`;

export const DogProfileModalButtonBox = styled.div`
    display: flex;
    height: 60px;
    text-align: center;
    justify-content: flex-end;
`;

export const DogProfileModalCloseButtonBox = styled.div`
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
`;

export const DogProfileModalImageBox = styled.div`
    display: flex;
    height: 580px;
    justify-content: center;
    text-align: center;
    align-items: center;

    @media (max-width: 768px) {
        height: 350px;
    }
`;

export const DogProfileModalImage = styled.div`
    width: 550px;
    height: 550px;

    @media (max-width: 768px) {
        width: 300px;
        height: 300px;
    }
`;

export const DogProfileModalBottomButtonBox = styled.div`
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
`;
