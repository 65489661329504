import { axiosGet, axiosLogin, axiosPost } from "../CommonAPI";

export const getUserHealthAPI = async ({ data, config }) =>
    axiosGet("/authority/health", data, config);

// 회원정보
export const postUserSignupAPI = async ({ data, config }) =>
    axiosPost("/user/signup", data, config);

// 로그인
export const postUserLoginAPI = async ({ data, config }) =>
    axiosLogin("/user/login", data, config);

export const getUserInfoAPI = async ({ data, config }) =>
    axiosGet("/user/info", data, config);

// 회원포인트
export const getUserPointAPI = async ({ data, config }) =>
    axiosGet("/user/point", data, config);
